// react
import React, { useState, useContext, useEffect, useNavigate } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_LOOKUPS } from '../../../operations/queries/lookup'

// components
// import GradeSearchForm from './GradeSearchForm'
import Listing from '../../Listing'
import LookupSearchForm from './LookupSearchForm'

const getDistrictName = record => {
  return record.district?.name ? record.district.name : ""  //If user is a superadmin they will only get global entries which won't have a district name
}

const LookupList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')

  const [selectedLookupType, setSelectedLookupType] = useState()
  const DEFAULT_TYPE_SELECTED = 'Strengths'
  const [currentPage, setCurrentPage] = useState(1)

  const [criterias, setCriterias] = useState({
    districtId: Auth.currentDistrictId?.id,
    type: DEFAULT_TYPE_SELECTED
  })
  const canAddLookups = Auth.hasPermission('ADD_LOOKUPS')
  const extra = (
    <>
      {canAddLookups && (
        <Tooltip title='Add new lookup'>
          <Link to='../lookup/add'>
            <Button type='primary'>Add Lookup</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    console.log(criterias, 'test')
    if (criterias.type !== undefined) {
      setSelectedLookupType(criterias.type)
      setCriterias(criterias)
      console.log('onSearch', criterias, selectedLookupType)
    }
  }
  const canEditGrades = Auth.hasPermission('EDIT_GRADES')
  const columns = [
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: (a, b) => a.value.localeCompare(b.value),
      render: (value, record) => (
        <div>
          <div>
            {canEditGrades ? (
              <Link to={`../lookup/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    },
    {
      title: 'District',
      dataIndex: 'districtName',
      sorter: (a, b) => getDistrictName(a).localeCompare(getDistrictName(b)),
      render: (value, record) => <>{getDistrictName(record)} </>
    }
  ]

  useEffect(() => {
    setCriterias({ districtId: Auth.currentDistrictId?.id, type: 'Assessment' })
  }, [])

  return (
    <>
      <Listing
        query={QUERY_LOOKUPS}
        columns={columns}
        dataKey='lookups'
        criterias={criterias}
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Filter' bordered={false}>
                  <LookupSearchForm
                    onSearch={onSearch}
                    defaultType={DEFAULT_TYPE_SELECTED}
                    selectedLookupType={selectedLookupType}
                  />
                </Card>
              </div>
              <div className='page-container'>
                <Card title='Lookups' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default LookupList
