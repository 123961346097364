import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import LoginForm from '../../components/sections/login/LoginForm'
import { enquireScreen } from 'enquire-js'
import { Typography, Layout, Row, Col, Card, Image, Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
// contexts
import AppContext from '../../utils/appProvider'
import './HomeStyles.css'
import itames from './iTAMES Logo.png'
import generic from './TAMES Simple Logo.png'
import tamesassist from '../../assets/images/tames_assist_logo.png'
const Landing = () => {
    const [isMobile, setIsMobile] = useState()

    const app = useContext(AppContext)
    const { Paragraph, Text } = Typography;
    const { Meta } = Card;
    const { Header, Footer, Sider, Content } = Layout;
    const history = useNavigate()

    useEffect(() => {
        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [])

    return (
        <>
            {
                isMobile ? <>
                    <Layout style={{ minHeight: "100vh" }}>
                        <Header style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            display: 'flex',
                            // alignItems: 'center',
                        }} >
                            <Row>
                                <Image src={generic} preview={false} style={{ width: '10rem' }} />

                            </Row>
                            <Row>
                                <Typography.Title style={{ color: 'white', margin: '0px', paddingTop: '4rem', width: '500rem', fontSize: '1em' }}>
                                    Health Resource Services
                                </Typography.Title>

                            </Row>



                        </Header>
                        <Content style={{ paddingTop: '6rem' }}>
                            <Row justify={'center'} align={'middle'} >

                                <Col className="fade-in">
                                    <Card
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flex: 1,
                                            width: '20rem',
                                            marginBottom: '1em'
                                        }}
                                    >

                                        <Image src={tamesassist} preview={false} />
                                        <Row>
                                            <Col style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flex: 1,
                                            }}>
                                                <Button size={'large'} style={{ transform: 'scale(1)' }} onClick={() => {
                                                    history('/signin')
                                                }}>
                                                    Sign In

                                                </Button>

                                            </Col>
                                            <Col style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flex: 1,
                                            }}
                                            >
                                                <Button size={'large'} style={{ transform: 'scale(1)' }} onClick={() => {
                                                    history('/')
                                                }}>
                                                    More Info
                                                </Button>


                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '2rem' }}>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,

                                                }}
                                            >
                                                <Typography.Paragraph style={{ fontSize: '1.5em' }}>
                                                    For IEPs, 504s, Health Plans, and more.
                                                </Typography.Paragraph>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'middle'}>
                                <Col className="fade-in" >
                                    <Card
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flex: 1,
                                            width: '20rem'
                                        }}
                                    >
                                        <Row>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}
                                            >
                                                <Image src={itames} preview={false} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}
                                            >



                                                <Link to="https://www.itames.com/" >
                                                    <Button size={'large'} style={{ transform: 'scale(1)' }}>
                                                        Sign In
                                                    </Button>

                                                </Link>

                                            </Col>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}
                                            >
                                                <Link to="https://www.itames.com/" >
                                                    <Button size={'large'} style={{ transform: 'scale(1)' }}>

                                                        More Info
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '2rem' }}>
                                            <Col style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flex: 1,
                                            }}>
                                                <Typography.Paragraph style={{ fontSize: '1.5em' }}>
                                                    For medicaid billing, scheduling services, and more.
                                                </Typography.Paragraph>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Content>
                        <Footer style={{ backgroundColor: '#001529' }}>
                            <Row >
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flex: 1,

                                    }}
                                >
                                    <span style={{ color: 'white', fontSize: '.8em', paddingBottom: '1em' }}>
                                        © {new Date().getFullYear()} TAMES/Health Resource Service{' '}
                                        Management, Inc.
                                    </span>
                                </Col>
                            </Row>

                        </Footer>

                    </Layout></> :
                    <>
                        <Layout style={{ minHeight: "100vh" }}>
                            <Header style={{
                                position: 'absolute',
                                top: 0,
                                zIndex: 1,
                                width: '100%',
                                display: 'flex',
                                // alignItems: 'center',
                            }} >

                                <Image src={generic} preview={false} style={{ width: '10rem' }} />



                                <Typography.Title style={{ color: 'white', margin: '0px', paddingTop: '1rem' }}>
                                    Health Resource Services
                                </Typography.Title>
                            </Header>
                            <Content style={{ paddingTop: '6rem' }}>
                                <Row justify={'center'} align={'middle'} >
                                    <Col span={12} style={{ padding: '5rem', }} className="fade-in" >
                                        <Card
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flex: 1,
                                                // height: '30rem'
                                            }}
                                        >
                                            <Row>
                                                <Col
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Image src={itames} preview={false} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flex: 1,
                                                    }}
                                                >



                                                    <Link to="https://www.itames.com/" >
                                                        <Button size={'large'} style={{ transform: 'scale(1.5)' }}>
                                                            Sign In
                                                        </Button>

                                                    </Link>

                                                </Col>
                                                <Col
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Link to="https://www.itames.com/" >
                                                        <Button size={'large'} style={{ transform: 'scale(1.5)' }}>

                                                            More Info
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: '2rem' }}>
                                                <Col style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}>
                                                    <Typography.Paragraph style={{ fontSize: '2em' }}>
                                                        For medicaid billing, scheduling services, and more.
                                                    </Typography.Paragraph>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={12} style={{ padding: '5rem' }} className="fade-in">
                                        <Card
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flex: 1,
                                                height: '30rem'
                                            }}
                                        >
                                            <Row>
                                                <Col
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Image src={tamesassist} preview={false} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}>
                                                    <Button size={'large'} style={{ transform: 'scale(1.5)' }} onClick={() => {
                                                        history('/signin')
                                                    }}>
                                                        Sign In

                                                    </Button>

                                                </Col>
                                                <Col style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                }}
                                                >
                                                    <Button size={'large'} style={{ transform: 'scale(1.5)' }} onClick={() => {
                                                        history('/')
                                                    }}>
                                                        More Info
                                                    </Button>


                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: '2rem' }}>
                                                <Col
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flex: 1,

                                                    }}
                                                >
                                                    <Typography.Paragraph style={{ fontSize: '2em' }}>
                                                        For IEPs, 504s, Health Plans, and more.
                                                    </Typography.Paragraph>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Content>
                            <Footer style={{ backgroundColor: '#001529' }}>
                                {/* <Row style={{ padding: '0px', margin: '0px' }}>
                    <Col
                        style={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            flex: 1,
                        }}
                        span={5}
                    >
                        <Typography.Title copyable style={{ color: 'white', fontSize: '2rem' }}>
                            info@tameshrs.com
                        </Typography.Title>
                    </Col>
                    <Col
                        style={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            flex: 1,
                        }}
                        span={6}

                    >
                        <Typography.Title h1 copyable style={{ color: 'white', fontSize: '2rem' }}>
                            800-848-0157
                        </Typography.Title>
                    </Col>
                </Row> */}
                                <Row >
                                    <Col
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flex: 1,

                                        }}
                                    >
                                        <span style={{ color: 'white' }}>
                                            © {new Date().getFullYear()} TAMES/Health Resource Service{' '}
                                            Management, Inc.
                                        </span>
                                    </Col>
                                </Row>

                            </Footer>

                        </Layout>
                    </>
            }
        </>


    )
}

export default Landing
