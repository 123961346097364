// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// ant design
import { Alert, Button, Table as AntdTable } from 'antd'
import { Table, ExportTableButton } from 'ant-table-extensions'

// apollo
import { useQuery, useLazyQuery } from "@apollo/client";

// icons
import { FileExcelOutlined } from '@ant-design/icons'

import csvDownload from 'json-to-csv-export'

const Listing = ({
  query,
  columns,
  exportable,
  dataKey,
  criterias,
  render,
  expandedFields = true,
  expandData
}) => {
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  console.log(criterias, 'what are criterias here')
  const [getListingData, { loading, data, error }] = useLazyQuery(query, {
    variables: { ...criterias, ...pagination },
    enabled: false,
    staleTime: Infinity
  });

  useEffect(() => {
    getListingData();
  }, []);
  console.log(data, 'what is the data')
  //Disabled.  Use LazyQuery and useEffect above so that we only call query once on load
  // const { data, loading, error, fetchMore } = useQuery(query, {
  //   variables: { ...criterias, ...pagination },
  //   enabled: false,
  //   staleTime: Infinity
  // });

  const [exportData, setExportData] = useState({});

  useEffect(() => {
    if (!data) {
      return
    }

    if (!expandData) {
      setExportData(data[dataKey])
      return
    }
    const expandedData = expandData(data);
    setExportData(expandedData);
  }, [data]);

  if (error) {
    //Alternative error message containing more specific error message
    // const errorDescription = error.message ? error.message : 'An error occurred.'
    // return (<Alert type='error' description={errorDescription} showIcon banner />)    

    return (
      <Alert type='error' description='An error occurred' showIcon banner />
    )
  }

  const onPageChange = (page, pageSize) => {
    setPagination({
      offset: page * pageSize,
      limit: pageSize,
    });
    //Following is preliminary work for server pagination
    // fetchMore({
    //   variables: {
    //     offset: data.length,
    //     limit: pageSize
    //   },
    //   updateQuery: (prev, { fetchMoreResult }) => {
    //     if (!fetchMoreResult) return prev;
    //     console.log('prev/fetchmore',prev,fetchMoreResult)
    //     return Object.assign({}, prev.students, {
    //       students: [...prev.students, ...fetchMoreResult.students]
    //     });
    //   }
    // })
    // console.log(data.students.length)

  };

  const antdTable = (
    <AntdTable
      bordered
      pagination={{
        //pageSize: 10, //Let antd handle this based on sizeChanger selection
        showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
        showQuickJumper: true,
        showTotal: (data) => `${data.toLocaleString("en")} Total`,
        position: ["bottomRight", "topRight"],
        //onChange: onPageChange, //disable until server pagination implemented
      }}
      columns={columns}
      dataSource={data && data[dataKey]}
      loading={loading}
    />
  )

  // const table = (
  //   <Table
  //     bordered
  //     pagination={{
  //       pageSize: 10,
  //       showSizeChanger: false,
  //       position: ["bottomCenter"],
  //       onChange: onPageChange,
  //     }}
  //     style={{ backgroundColor: "@component-background" }}
  //     columns={columns}
  //     dataSource={data && data[dataKey]}
  //     loading={loading}
  //   />
  // );

  const exportCsvButton = (
    <ExportTableButton
      dataSource={data && data[dataKey]}
      columns={exportable?.exportTableButton?.columns}
      fields={exportable?.exportTableButton?.fields}
      btnProps={
        exportable?.exportTableButton?.btnProps
          ? exportable?.exportTableButton?.btnProps
          : { type: 'primary', icon: <FileExcelOutlined /> }
      }
      showColumnPicker
    >
      {exportable?.exportTableButton?.label ? (
        <>{exportable?.exportTableButton?.label}</>
      ) : (
        <>Export to CSVx</>
      )}
    </ExportTableButton>
  )

  const customExportButton = (
    <Button
      icon={<FileExcelOutlined />}
      type='primary'
      onClick={() => {
        csvDownload(exportData, 'table.csv')
      }}
    >
      Export to CSV
    </Button>
  )

  return (
    <>
      {render(
        exportable ? table : antdTable,
        expandedFields ? customExportButton : exportCsvButton
        //expandedFields ? customExportButton : exportCsvButton
      )}
    </>
  )
}

Listing.propTypes = {
  render: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  exportable: PropTypes.object,
  query: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  criterias: PropTypes.object.isRequired,
  expandedFields: PropTypes.bool,
  expandData: PropTypes.func
}

export default Listing
